<template>
  <main class="basicinfo">
    账号注销页面
  </main>
</template>

<script>
export default {
  name: 'Cancellation'
}
</script>

<style>

</style>